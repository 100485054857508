<template>
	<div>
		<a-layout>
			<a-layout-header>
				<div class="content flex space">
					<div class="flex alcenter ft24 text-w">
						海客云-管理中心
					</div>
					<div class="flex alcenter ft14 text-w">
						<span @click="pwdShow = true">欢迎您，{{ account_info.mobile }}</span> <a-button class="ml10" ghost @click="outLogin"><a-icon type="poweroff" /> 退出</a-button>
					</div>
				</div>
			</a-layout-header>
			<a-layout-content class="content" style="margin-top: 15px;">
				<div class="bg-w">
					<div class="pd30">
						<div class="flex space">
							<div v-if="create_num < account_info.num && account_info.role > 1">
								<a-button type="primary" icon="plus" @click="createFirmShow = true">添加</a-button>
								<span class="ml10" v-if="account_info.num > 0">{{ create_num }}/{{ account_info.num }}</span>
							</div>
							
							<div class="form-search-box ">
								<a-form layout="inline">
									<a-form-item label="关键字">
										<a-input v-model="keyword" placeholder="请输入关键字"></a-input>
									</a-form-item>
									<a-form-item>
										<a-button @click="searchAct" type="primary">搜索</a-button>
									</a-form-item>
								</a-form>
							</div>
							
						</div>
						<div class="mt20">
							<div class="wxb-table-gray">
								<a-table rowKey="firm_id" :columns="columns" :pagination="pagination" @change="change" :data-source="list"
								:loading="loading">
									<template slot="accountType" slot-scope="record,index">
										<a-tag color="purple" v-if="record.role_id == 1">创始人</a-tag>
										<a-tag color="blue" v-else>管理员</a-tag>
									</template>
									<template slot="action" slot-scope="record,index">
										<a-button @click="toLogin(record)">进入管理</a-button>
									</template>
								</a-table>
							</div>
						</div>
					</div>
				</div>
			</a-layout-content>
		</a-layout>
		<a-modal title="添加" :visible="createFirmShow" width="400px" @ok="createFirm" @cancel="()=>{ createFirmShow = false }">
			<a-input v-model="firm_name"  placeholder="请输入页面标题" />
		</a-modal>
		<a-modal title="修改密码" :visible="pwdShow" width="400px" @ok="editPwd" @cancel="()=>{ pwdShow = false }">
			<a-input v-model="pwdData.pwd"  placeholder="请输入新密码" />
			<a-input v-model="pwdData.repwd"  placeholder="请再次输入新密码" class="mt10" />
		</a-modal>
	</div>

</template>

<script>
	import { apiAccountInfo, apiFirmList, apiAccountEditPwd, apiCreateFirm, apiLoginAdmin } from '../utils/js/api.js'
	import { message } from 'ant-design-vue'
	export default {
		data() {
			return {
				createFirmShow:false,
				pwdShow:false,
				account_info:{},
				list:[],
				create_num:0,
				firm_name:'',
				pwdData:{
					pwd:'',
					repwd:''
				},
				keyword:'',
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'firm_id',
						align: 'center',
						ellipsis: true
					},
					{
						title: '名称',
						dataIndex: 'firm_name',
						align: 'center',
						ellipsis: true
					},
					{
						title: '创建时间',
						dataIndex: 'create_time',
						align: 'center',
						ellipsis: true
					},
					{title: '类型',key: 'accountType',align: 'center',scopedSlots: {customRender: 'accountType'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		created(){
			apiAccountInfo().then(res=>{
				this.account_info = res
			})
			this.getLists();
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				apiFirmList({
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					keyword:this.keyword,
				}).then(res=>{
					this.pagination.total = res.total;
					this.list = res.list;
					this.create_num = res.create_num;
					this.loading = false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			showAreateFirm(){
				if(this.account_info.role === 1 && (this.account_info.num === 0 || account_info.num > create_num)){
					this.createFirmShow = true
				}else{
					this.$message.error('没有权限');
				}
				
			},
			//创建
			createFirm(){
				apiCreateFirm({firm_name:this.firm_name}).then(res=>{
					this.$message.success(res);
					this.getLists();
				})
				this.createFirmShow = false;
			},
			
			searchAct(){
				this.pagination.current = 1;
				this.getLists();
			},
			
			editPwd(){
				if(this.pwdData.pwd && this.pwdData.repwd){
					apiAccountEditPwd(this.pwdData).then(res=>{
						console.log(res)
						message.success(res);
						this.pwdShow = false
						setTimeout(()=>{
							localStorage.removeItem('xhmmp-account-token');
							this.$router.push('/login');
						},1000)
					})
				}
				
			},
			outLogin(){
				localStorage.removeItem('xhmmp-account-token');
				this.$router.push('/login');
			},
			
			change(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			toLogin(record){
				apiLoginAdmin({
					firm_id:record.firm_id
				}).then(res=>{
					console.log('res',res)
					window.open(res.url);
				})
				
			},
			
		}
	}
</script>

<style>
	.content{
		max-width: 1200px;
		margin: 0 auto;
	}
	
</style>